<div class="flex flex-col justify-between border-r border-[#737373]/43 h-full ">
  <div>
    <div class="flex justify-between items-center mb-5 px-2 py-3 md:py-5 md:pl-5 md:pr-5">
      <img src="../../../../assets/img/New%20Logo%20inhub%20.png" alt="" class="w-20 md:w-35 " >
      <img src="../../../../assets/img/hamburger.svg" alt="" (click)="onHamburger()" class=" w-6 cursor-pointer" *ngIf="screenSize !== 'sm'">
    </div>
    <div>
      <div
        *ngFor="let item of optionsTop"
        (click)="navigation(item[2])"
        [ngClass]="screen === item[2] ? 'div-line-a-active' : 'div-line-a'">
        <div class="flex w-full">
          <div>
            <img
              src="{{screen === item[2] ? item[3] : item[0]}}"
              alt=""
              [ngClass]="(screenSize === 'sm' || !viewText) ? 'w-8 ml-5' : 'mr-2 w-5 '">
          </div>
          <p
            *ngIf="screenSize !== 'sm' && viewText">
            {{item[1] | translate}}
          </p>
          <div class="ml-auto mr-5">
            <div class="relative" *ngIf="item[1] === 'NOTIFICATIONS' && viewText">
              <svg class="w-5 h-5 text-[#737373] animate-wiggle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M15.585 15.5H5.415A1.65 1.65 0 0 1 4 13a10.526 10.526 0 0 0 1.5-5.415V6.5a4 4 0 0 1 4-4h2a4 4 0 0 1 4 4v1.085c0 1.907.518 3.78 1.5 5.415a1.65 1.65 0 0 1-1.415 2.5zm1.915-11c-.267-.934-.6-1.6-1-2s-1.066-.733-2-1m-10.912 3c.209-.934.512-1.6.912-2s1.096-.733 2.088-1M13 17c-.667 1-1.5 1.5-2.5 1.5S8.667 18 8 17"/></svg>
              <div class="px-1 bg-red-500 rounded-full text-center text-white text-xs absolute -top-3 -end-2">
                {{unreadNotificationCount}}
                <div class="absolute top-0 start-0 rounded-full -z-10 animate-ping bg-red-200 w-full h-full" ></div>
              </div>
            </div>
            <div class="relative" *ngIf="item[1] === 'NOTIFICATIONS' && !viewText && unreadNotificationCount != 0">
              <div class="w-2 h-2 bg-red-500 rounded-full"></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class=" flex flex-col ">
    <div>
      <div class="">
        <div
          *ngFor="let item of optionsBottom"
          (click)="navigation(item[2])"
          [ngClass]="screen === item[2] ? 'div-line-a-active' : 'div-line-a'">
          <img
            src="{{screen === item[2] ? item[3] : item[0]}}" alt=""
            [ngClass]="(screenSize === 'sm' || !viewText) ? 'w-8 ml-5' : 'mr-2 w-5 '">
          <p
            *ngIf="screenSize !== 'sm' && viewText">
            {{item[1] | translate}}
          </p>
        </div>
      </div>
    </div>
    <div class="flex border-t-2 border-[#E5E5E5] py-5">
      <div class="flex mx-2 md:mx-5 pr-5 cursor-pointer" (click)="navigation('/setting')">
        <img [src]="picture || '../../../assets/icons/icon-user.png'" alt="" class="mr-2 w-10 h-10 rounded-full bg-[#AAAAAA]">
        <div *ngIf="screenSize !== 'sm' && viewText">
          <p class="font-poppins text-black text-sm"> {{fullName}}</p>
          <p class="font-dmsans text-[#6C6C6C] text-xs">{{email}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
