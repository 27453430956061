<div class="div-center w-full h-full px-4 pb-7 pt-1 flex flex-col bg-white min-w-lg">
    <div class="flex w-full p-1 pt-3 border-b-2 border-[#E5E5E5] mb-7">
        <div class="flex items-center p-3">
            <img src="../../../assets/img/users.svg" alt="" class="mr-2">
            <p class="text-[#000] text-2xl font-semibold">{{'YOUR_OPINION' | translate}}</p>
        </div>
        <div class="flex items-center ml-auto cursor-pointer pr-5" (click)="closeDialog()">
            <img src="../../../assets/img/cancel.svg" alt="" class="">
        </div>
    </div>

    <div class="flex justify-center items-center font-dmsans">
        <p class="text-center">{{'YOUR_OPINION_DESC' | translate}}</p>
    </div>

    <div class="px-10 md:px-15">
        <div class="text-4xl my-12 flex justify-center">
            <i class="fa fa-star cursor-pointer mr-5" [ngClass]="{'text-[#408A7E]': selectedStars >= 1, 'text-[#EEEEEE]': selectedStars < 1}" (click)="onRate(1)"></i>
            <i class="fa fa-star cursor-pointer mr-5" [ngClass]="{'text-[#408A7E]': selectedStars >= 2, 'text-[#EEEEEE]': selectedStars < 2}" (click)="onRate(2)"></i>
            <i class="fa fa-star cursor-pointer mr-5" [ngClass]="{'text-[#408A7E]': selectedStars >= 3, 'text-[#EEEEEE]': selectedStars < 3}" (click)="onRate(3)"></i>
            <i class="fa fa-star cursor-pointer mr-5" [ngClass]="{'text-[#408A7E]': selectedStars >= 4, 'text-[#EEEEEE]': selectedStars < 4}" (click)="onRate(4)"></i>
            <i class="fa fa-star cursor-pointer mr-5" [ngClass]="{'text-[#408A7E]': selectedStars >= 5, 'text-[#EEEEEE]': selectedStars < 5}" (click)="onRate(5)"></i>
        </div>

        <div class="w-full mb-12">
            <textarea type="text" class="w-full h-[15vh] px-3 py-2 textarea bg-[#EBEBEB] border-2 border-[#B3B3B3] rounded-lg" placeholder="{{'ADD_COMMENT' | translate}}" [(ngModel)]="description"></textarea>
        </div>

        <div class="w-full flex justify-center mb-5">
            <button type="submit" (click)="submit()" class="w-full bg-[#408A7E] text-white rounded-lg py-2 shadow-md font-dmsans text-base">
                {{'SUBMIT' | translate}}
            </button>
        </div>
    </div>
</div>

